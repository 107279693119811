const baseStyle = `text-sm font-medium`;

const PercentChange: React.FC<{ change?: number; period?: string }> = ({
  change,
  period = 'today',
}) => {
  if (change === undefined) {
    return <span className={baseStyle}>---</span>;
  }
  const sign = change > 0 ? '+' : '';
  const color = change > 0 ? 'text-green-500' : 'text-red-600';
  return (
    <span className={`${baseStyle} ${color}`}>
      {sign}
      {change ? change.toFixed(2) : '---'}% {period}
    </span>
  );
};

export default PercentChange;
