import * as anchor from '@project-serum/anchor';
import { PublicKey, Keypair } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { OptionContract, SoloptionsProgram } from './types';

interface ExerciseOptionParams {
  exerciserAccount?: Keypair;
  quoteTokenSource: PublicKey;
  optionTokenSource: PublicKey;
  underlyingTokenDestination: PublicKey;
  amount: number;
}

export const exerciseOption = async (
  program: SoloptionsProgram,
  contract: OptionContract,
  params: ExerciseOptionParams
) => {
  const {
    amount,
    exerciserAccount,
    quoteTokenSource,
    optionTokenSource,
    underlyingTokenDestination,
  } = params;
  await program.rpc.optionExercise(new anchor.BN(amount), {
    accounts: {
      contract: contract.publicKey,
      exerciserAuthority: exerciserAccount
        ? exerciserAccount.publicKey
        : program.provider.wallet.publicKey,
      quoteTokenSource,
      contractQuoteTokens: contract.quotePool,
      optionMint: contract.optionMint,
      optionTokenSource: optionTokenSource,
      contractUnderlyingTokens: contract.underlyingPool,
      underlyingTokenDestination,
      underlyingMint: contract.underlyingMint,
      quoteMint: contract.quoteMint,
      tokenProgram: TOKEN_PROGRAM_ID,
      clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
    },
    signers: exerciserAccount ? [exerciserAccount] : undefined,
  });
};
