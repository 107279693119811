import { useState } from 'react';
import { SoloptionsProgram, NewContractParams, newContract } from '@soloptions/client';

export const useInitializeContract = () => {
  const [loading, setLoading] = useState(false);
  return {
    loading,
    initialize: async (program: SoloptionsProgram, params: NewContractParams) => {
      // this can probably be swr
      try {
        setLoading(true);
        await newContract(program, params);
      } finally {
        setLoading(false);
      }
    },
  };
};
