import tw, { styled } from 'twin.macro';

export const Form = tw.form`p-4 flex flex-col items-stretch text-white gap-4`;
export const FormControl: React.FC<{ label: string }> = ({ label, children, ...props }) => {
  return (
    <div {...props} tw="space-y-2">
      <label tw="text-sm font-semibold">{label}</label>
      {children}
    </div>
  );
};
export const FormHeader = styled.header(({ alignCenter }: { alignCenter?: boolean }) => [
  tw`px-4 flex justify-between`,
  alignCenter && tw`items-center`,
]);
export const Input = styled.input(({ disabled }) => [
  tw`w-full px-4 py-3 outline-none transition bg-gray-800 text-white text-lg font-medium rounded-xl`,
  disabled ?? tw`focus:bg-gray-700 hover:bg-gray-700`,
  disabled && tw`cursor-not-allowed`,
]);
export const InputRight = tw.span`absolute right-4 text-gray-300 select-none`;
export const InputWrapper = tw.div`flex items-center relative`;
