import { PublicKey, Signer, Transaction } from '@solana/web3.js';
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, Token } from '@solana/spl-token';

import { Provider } from '@project-serum/anchor';
import { getTokenAccount } from '@project-serum/common';

export const getAssociatedTokenAddress = async (
  mint: PublicKey,
  owner: PublicKey,
  allowOffCurve?: boolean
) =>
  await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    mint,
    owner,
    allowOffCurve
  );

interface options {
  mint: PublicKey;
  owner: PublicKey;
  payer?: PublicKey;
  signers?: Signer[];
}

export const getOrCreateAssociatedTokenAccount = async (
  provider: Provider,
  { mint, owner, payer, signers }: options
) => {
  const address = await getAssociatedTokenAddress(mint, owner);
  try {
    const res = await getTokenAccount(provider, address);
    // return (await getTokenAccount(provider, address)).address;
    return address;
  } catch (e) {
    if (e instanceof Error && e.message.match(/Failed to find token account/)) {
      const tx = new Transaction().add(
        Token.createAssociatedTokenAccountInstruction(
          ASSOCIATED_TOKEN_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          mint,
          address,
          owner,
          payer ?? owner
        )
      );
      await provider.send(tx, signers);
      return address;
    }
    throw e;
  }
};
