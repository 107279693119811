import { Connection, PublicKey } from '@solana/web3.js';

const {
  SOLANA_NETWORK = 'http://127.0.0.1:8899',
  PROGRAM_ID = new PublicKey('5kCvRJfbtag4NcsPk6vjzxaksmRmD76LkHjzt9s6Uwpk'),
} = process.env;

export const getConnection = () => {
  return new Connection(SOLANA_NETWORK);
};
