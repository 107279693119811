import { TokenInfo } from '~/src/types';

// TODO(cqsd): conditional devnet/mainnet

export const wbtc: TokenInfo = {
  logoURI:
    'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/qfnqNqs3nCAHjnyCgLRDbBtq4p2MtHZxw8YjSyYhPoL/logo.png',
  symbol: 'BTC',
  name: 'Wrapped Bitcoin',
  chainId: 0,
  address: 'Wbt2CgkkD3eVckD5XxWJmT8pTnFTyWrwvGM7bUMLvsM',
  decimals: 9,
  tags: [],
};

export const usdc: TokenInfo = {
  logoURI:
    'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png',
  symbol: 'USDC',
  name: 'USD Coin',
  chainId: 0,
  address: '2tWC4JAdL4AxEFJySziYJfsAnW2MHKRo98vbAPiRDSk8',
  decimals: 9,
  tags: [],
};

export const wsol: TokenInfo = {
  chainId: 101,
  address: 'So11111111111111111111111111111111111111112',
  symbol: 'SOL',
  name: 'Wrapped SOL',
  decimals: 9,
  logoURI:
    'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png',
  tags: [],
  // extensions: {
  //   website: 'https://solana.com/',
  //   serumV3Usdc: '9wFFyRfZBsuAha4YcuxcXLKwMxJR43S7fPfQLusDBzvT',
  //   serumV3Usdt: 'HWHvQhFmJB3NUcu1aihKmrKegfVxBEHzwVX6yZCKEsi1',
  //   coingeckoId: 'solana',
  // },
};

export const weth: TokenInfo = {
  chainId: 101,
  address: '2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk',
  symbol: 'ETH',
  name: 'Wrapped Ethereum', // sollet
  decimals: 6,
  logoURI:
    'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk/logo.png',
  tags: ['wrapped-sollet', 'ethereum'],
};

const tradeable = {
  wbtc,
  weth,
  wsol,
};

export const all = Object.values(tradeable);

export default tradeable;
