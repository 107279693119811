import tw, { styled, theme } from 'twin.macro';
import { useWalletKit } from '@gokiprotocol/walletkit';
import { ConnectedWallet, useConnectedWallet } from '@saberhq/use-solana';
import { HiViewGrid } from 'react-icons/hi';
import { NavLink as BaseNavLink } from 'react-router-dom';
import { useState } from 'react';
import Modal from '~/src/components/common/Modal';

const buttonBaseStyle = tw`rounded-md px-4 py-2 text-sm font-semibold transition duration-200`;

const HoverableNavLink = styled(BaseNavLink)({
  '&:not(.active)': {
    color: theme`colors.gray.200`,
    '&:hover': {
      color: 'white',
    },
  },
});

const NavItem: React.FC<{ to: string }> = (props) => {
  return (
    <HoverableNavLink
      {...props}
      exact
      css={buttonBaseStyle}
      activeClassName="active text-white bg-gray-600"
    />
  );
};

const WalletConnectButton: React.FC = () => {
  const { connect } = useWalletKit();
  return (
    <button
      css={[buttonBaseStyle, tw`text-white font-bold bg-green-700 hover:bg-green-600`]}
      onClick={connect}
    >
      Connect wallet
    </button>
  );
};

const WalletDisconnectButton: React.FC<{ wallet: ConnectedWallet }> = ({ wallet }) => {
  const pubKey = wallet.publicKey.toString();
  const text = pubKey.slice(0, 4) + '...' + pubKey.slice(-5, -1);
  const [disconnectVisible, setDisconnectVisible] = useState(false);
  return (
    <button
      css={buttonBaseStyle}
      tw="relative overflow-hidden text-white font-bold hover:bg-red-800 bg-gray-900"
      onClick={() => wallet.disconnect()}
      onMouseEnter={() => setDisconnectVisible(true)}
      onMouseLeave={() => setDisconnectVisible(false)}
    >
      {/* idk why it takes so much to make this work */}
      <span tw="font-mono">{text}</span>
      <Modal tw="absolute inset-0 duration-200" visible={disconnectVisible}>
        <p tw="flex items-center justify-center h-full bg-red-800">Disconnect</p>
      </Modal>
    </button>
  );
};

export const Nav = () => {
  const wallet = useConnectedWallet();

  return (
    <header className="grid sm:grid-cols-3 p-4 container max-w-6xl mx-auto">
      <div className="hidden sm:flex items-center gap-2 text-white">
        <span className="text-3xl text-green-500">
          <HiViewGrid />
        </span>
        <span className="text-2xl font-bold">SolOptions</span>
      </div>
      <div className="flex justify-center">
        <div className="flex items-center bg-gray-800 border-8 border-gray-900 rounded-xl">
          <NavItem to="/">New Contract</NavItem>
          <NavItem to="/markets">Markets</NavItem>
          <NavItem to="/balances">Balances</NavItem>
        </div>
      </div>
      <div className="hidden sm:flex items-center justify-end gap-2">
        {!wallet?.connected ? <WalletConnectButton /> : <WalletDisconnectButton wallet={wallet} />}
      </div>
    </header>
  );
};
