import { Provider } from '@project-serum/anchor';
import { useMemo } from 'react';
import { useConnectedWallet } from '@saberhq/use-solana';
import { PublicKey } from '@solana/web3.js';
import { SoloptionsProgram } from '@soloptions/client';
import idl from '../soloptions.json';
import { getConnection } from '../utils/connection';

const { PROGRAM_ID = new PublicKey('5kCvRJfbtag4NcsPk6vjzxaksmRmD76LkHjzt9s6Uwpk') } = process.env;
// const programID = new PublicKey(idl.metadata.address);

export const useProvider = () => {
  const wallet = useConnectedWallet();
  const opts = {
    preflightCommitment: 'processed',
  };

  return useMemo(() => {
    if (wallet && wallet.connected) {
      const connection = getConnection();
      // @ts-ignore
      const provider = new Provider(connection, wallet, opts.preflightCommitment);
      // @ts-ignore
      const program = new SoloptionsProgram(idl, PROGRAM_ID, provider);
      return {
        provider,
        program,
      };
    }
    return {};
  }, [wallet]);
};
