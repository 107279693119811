import React from 'react';
import { format } from 'date-fns';

import DefaultLayout from '~/src/components/layouts/DefaultLayout';
import LoadingModal from '~/src/components/common/LoadingModal';
import { ConnectWalletButton } from '~/src/components/common/SubmitButton';
import { UserOptionBalance } from '~/src/types';
import { getTokenForContract } from '~/src/utils';
import { useOptionBalances } from '~/src/hooks/useOptionBalances';
import { useProvider } from '~/src/hooks';

const ContractBalance = ({ balance: b }: { balance: UserOptionBalance }) => {
  const { program, provider } = useProvider();

  const exercise = async () => {
    if (!program || !provider) {
      return;
    }
    alert(JSON.stringify(b));

    // const contract = b.contract;
    // const quoteTokenSource = await getOrCreateAssociatedTokenAccount(provider, {
    //   mint: contract.quoteMint,
    //   owner: provider.wallet.publicKey,
    // });
    // const optionTokenSource = await getOrCreateAssociatedTokenAccount(provider, {
    //   mint: contract.optionMint,
    //   owner: provider.wallet.publicKey,
    // });
    // const underlyingTokenDestination = await getOrCreateAssociatedTokenAccount(provider, {
    //   mint: contract.underlyingMint,
    //   owner: provider.wallet.publicKey,
    // });

    // await exerciseOption(program, b.contract, {
    //   amount: 1,
    //   quoteTokenSource,
    //   optionTokenSource,
    //   underlyingTokenDestination,
    // });
  };
  const redeem = async () => {
    if (!program || !provider) {
      return;
    }
    // const contract = b.contract;
    // const quoteTokenDestination = await getOrCreateAssociatedTokenAccount(provider, {
    //   mint: contract.quoteMint,
    //   owner: provider.wallet.publicKey,
    // });
    // const redeemerTokenSource = await getOrCreateAssociatedTokenAccount(provider, {
    //   mint: contract.writerMint,
    //   owner: provider.wallet.publicKey,
    // });
    // const underlyingTokenDestination = await getOrCreateAssociatedTokenAccount(provider, {
    //   mint: contract.underlyingMint,
    //   owner: provider.wallet.publicKey,
    // });
    // await redeemOption(program, contract, {
    //   underlyingTokenDestination,
    //   quoteTokenDestination,
    //   redeemerTokenSource,
    //   amount: 1,
    // });
  };

  // XXX: refactor
  const { contract } = b;
  const token = getTokenForContract(contract); // XXX(cqsd): tokens from this are hardcoded
  // const size = getContractSize(token);
  const strike = contract.strike.toNumber();
  const expiry = new Date(contract.expiryTs.toNumber());
  const direction = 'call';

  // TODO(sbb): get the underlying mint decimals dynamically
  const size = contract.underlyingAmount.toNumber() / 1_000_000_000; // XXX this is zero?
  // hack for UI rn b/c of the previous incorrect contracts
  const contractSize = size > 0.0001 ? size : 1;

  return (
    <button
      tw="flex items-center justify-between text-white bg-gray-800 hover:bg-gray-700 transition rounded-2xl px-4 py-2"
      onClick={exercise}
    >
      <div tw="flex items-center gap-2">
        <div tw="text-left text-sm">
          <p tw="font-medium">
            {contractSize} {token.symbol} @ ${strike} {direction}
          </p>
          <p>{format(expiry, `MMM dd ''yy`)}</p>
        </div>
      </div>
      {/* <div tw="text-right">
        <p tw="text-sm font-medium">${optionPrice.toFixed(2)}</p>
        <PercentChange change={change} />
      </div> */}
    </button>
  );
};

const Page = () => {
  const { userOptionBalances, loading } = useOptionBalances();
  const { provider } = useProvider();

  return (
    <React.Fragment>
      {/* TODO(cqsd): refactor */}
      {/* ugly conditionals here to show the "connect wallet" button */}
      {loading ? (
        <div className="min-h-[8rem]"></div>
      ) : (
        <div tw="p-4 flex flex-col items-stretch gap-4">
          {provider && <p tw="font-semibold px-4 text-white">Balances</p>}
          {provider &&
            (userOptionBalances.length ? (
              userOptionBalances.map((b, i) => <ContractBalance key={i} balance={b} />)
            ) : (
              <p tw="p-4 text-center text-white font-semibold">No balances found</p>
            ))}
          {!provider && <ConnectWalletButton>Connect wallet</ConnectWalletButton>}
        </div>
      )}
      <LoadingModal visible={loading} text="Loading balances" />
    </React.Fragment>
  );
};

export default () => (
  <DefaultLayout>
    <Page />
  </DefaultLayout>
);
