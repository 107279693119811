import tw, { styled } from 'twin.macro';
import { useWalletKit } from '@gokiprotocol/walletkit';

const baseStyles = tw`rounded-xl w-full py-3 flex items-center justify-center text-white text-lg font-semibold transition`;
const BaseButton = styled.button<{ loading?: boolean }>(baseStyles);

export const SubmitButton = styled(BaseButton)(({ loading, disabled }) => [
  loading || disabled ? tw`bg-gray-600` : tw`bg-gray-800 hover:bg-blue-800`,
]);

// TODO(cqsd): refactor (wrong file for this button)
const WarningButton = styled(BaseButton)(({ loading, disabled }) => [
  loading || disabled ? tw`bg-gray-600` : tw`bg-red-900 hover:bg-red-800`,
]);
export const ConnectWalletButton: React.FC = ({ ...props }) => {
  const { connect } = useWalletKit();
  return <WarningButton {...props} type="button" onClick={connect} />;
};
