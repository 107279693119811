{
  "version": "0.0.0",
  "name": "soloptions",
  "instructions": [
    {
      "name": "newContract",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "contract",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "writerMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "optionMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "underlyingMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "quoteMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "underlyingPool",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "quotePool",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "strike",
          "type": "u64"
        },
        {
          "name": "underlyingAmount",
          "type": "u64"
        },
        {
          "name": "quoteAmount",
          "type": "u64"
        },
        {
          "name": "expiryTs",
          "type": "u64"
        },
        {
          "name": "bumps",
          "type": {
            "defined": "Bumps"
          }
        }
      ]
    },
    {
      "name": "optionWrite",
      "accounts": [
        {
          "name": "writerAuthority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "contract",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "userUnderlyingFundingTokens",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "underlyingPool",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "writerTokenDestination",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "optionTokenDestination",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "writerMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "optionMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "underlyingMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "quoteMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "writeAmount",
          "type": "u64"
        }
      ]
    },
    {
      "name": "optionExercise",
      "accounts": [
        {
          "name": "exerciserAuthority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "contract",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "quoteTokenSource",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "contractQuoteTokens",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "optionMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "optionTokenSource",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "contractUnderlyingTokens",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "underlyingTokenDestination",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "underlyingMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "quoteMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "numContracts",
          "type": "u64"
        }
      ]
    },
    {
      "name": "optionRedeem",
      "accounts": [
        {
          "name": "redeemerAuthority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "contract",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "redeemerTokenSource",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "writerMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "contractUnderlyingTokens",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "contractQuoteTokens",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "underlyingTokenDestination",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "quoteTokenDestination",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "underlyingMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "quoteMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "numContracts",
          "type": "u64"
        }
      ]
    }
  ],
  "accounts": [
    {
      "name": "OptionsContract",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "underlyingMint",
            "type": "publicKey"
          },
          {
            "name": "quoteMint",
            "type": "publicKey"
          },
          {
            "name": "strike",
            "type": "u64"
          },
          {
            "name": "expiryTs",
            "type": "u64"
          },
          {
            "name": "bumps",
            "type": {
              "defined": "Bumps"
            }
          },
          {
            "name": "underlyingAmount",
            "type": "u64"
          },
          {
            "name": "quoteAmount",
            "type": "u64"
          },
          {
            "name": "writerMint",
            "type": "publicKey"
          },
          {
            "name": "optionMint",
            "type": "publicKey"
          },
          {
            "name": "underlyingPool",
            "type": "publicKey"
          },
          {
            "name": "quotePool",
            "type": "publicKey"
          }
        ]
      }
    }
  ],
  "types": [
    {
      "name": "Bumps",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "contract",
            "type": "u8"
          },
          {
            "name": "writer",
            "type": "u8"
          },
          {
            "name": "option",
            "type": "u8"
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 300,
      "name": "Unauthorized",
      "msg": "Unauthorized."
    },
    {
      "code": 301,
      "name": "InsufficientCollateral",
      "msg": "Insufficient collateral to write options."
    },
    {
      "code": 302,
      "name": "InvalidContractExpiry",
      "msg": "Invalid contract expiry."
    },
    {
      "code": 303,
      "name": "ContractExpired",
      "msg": "Options contract is expired."
    },
    {
      "code": 304,
      "name": "ContractNotYetExpired",
      "msg": "Cannot redeem until contract expiry."
    },
    {
      "code": 305,
      "name": "InvalidStrike",
      "msg": "Strike must be greater than 0."
    },
    {
      "code": 306,
      "name": "InvalidMultiplier",
      "msg": "Multiplier must be between 1 and 1000000"
    },
    {
      "code": 307,
      "name": "RedeemBeforeExercise",
      "msg": "Tried to redeem before exercise"
    }
  ],
  "metadata": {
    "address": "5kCvRJfbtag4NcsPk6vjzxaksmRmD76LkHjzt9s6Uwpk"
  }
}
