import useSWR from 'swr';
import { useEffect, useState } from 'react';
import { PriceData, TokenInfo } from '~/src/types';

interface PriceResponse {
  usd: number;
  usd_24h_change: number;
}

const TICKER_TO_NAME: Record<string, string> = {
  BTC: 'bitcoin',
  ETH: 'ethereum',
  SOL: 'solana',
};
const NAME_TO_TICKER: Record<string, string> = Object.entries(TICKER_TO_NAME).reduce(
  (ret, entry) => {
    const [key, value] = entry;
    ret[value] = key;
    return ret;
  },
  {} as Record<string, string>
);

const fetcher = async (ids: string) => {
  const url = `https://api.coingecko.com/api/v3/simple/price?ids=${ids}&vs_currencies=usd&include_24hr_change=true`;
  return (await fetch(url).then((r) => r.json())) as Record<string, PriceResponse>;
};
export const useMarketPrices = (currencies: Array<TokenInfo>) => {
  // comma separated list of names for coingecko API
  const ids = currencies.map((c) => TICKER_TO_NAME[c.symbol]).join(',');

  const [priceData, setPriceData] = useState<Record<string, PriceData>>({});
  const { data, error } = useSWR(ids, fetcher);

  useEffect(() => {
    if (!data || error) {
      return;
    }
    const _priceData = Object.keys(data).reduce((mapping, name) => {
      const { usd: price, usd_24h_change: dayChange } = data[name];
      const ticker = NAME_TO_TICKER[name];
      mapping[ticker] = { price, dayChange };
      return mapping;
    }, {} as Record<string, PriceData>);
    setPriceData(_priceData);
  }, [data]);

  return { priceData };
};
