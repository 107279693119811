import tw, { styled } from 'twin.macro';
import React from 'react';
import { PropagateLoader } from 'react-spinners';

interface LoadingProps {
  text: string;
}
const Loading: React.FC<LoadingProps> = ({ text }) => {
  return (
    <div tw="absolute top-1/2 bottom-1/2 w-full flex flex-col items-center gap-4 justify-center">
      <p tw="text-white font-bold">{text}</p>
      <PropagateLoader color="white" size={12} />
    </div>
  );
};

const Background = styled.div<{ visible: boolean }>(({ visible }) => [
  tw`absolute inset-0 transition bg-gray-900`,
  visible ? tw`visible opacity-90` : tw`invisible opacity-0`,
]);
const LoadingModal: React.FC<{ visible: boolean } & LoadingProps> = ({ visible, text }) => {
  return (
    <React.Fragment>
      <Background visible={visible} />
      {visible && <Loading text={text} />}
    </React.Fragment>
  );
};

export default LoadingModal;
