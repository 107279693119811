import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import InitializeContract from './pages/initialize';
import Balances from './pages/balances';
import Markets from './pages/markets';
import Trade from './pages/markets/trade';

export const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/" component={InitializeContract} />
      <Route exact path="/balances" component={Balances} />
      <Route exact path="/markets" component={Markets} />
      <Route exact path="/markets/trade" component={Trade} />
      <Redirect to="/" />
    </Switch>
  );
};
