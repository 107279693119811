/**
 * react-select with custom styles
 */
import BaseSelect, { StylesConfig, Props as SelectProps } from 'react-select';
import { theme } from 'twin.macro';

export type { Props as SelectProps } from 'react-select';

export const customStyles: StylesConfig = {
  control: (provided) => {
    return {
      ...provided,
      padding: '0.5rem 0.35rem', // p-2 after built-in padding oddities
      borderRadius: theme`borderRadius.xl`,
      border: 'none',
      backgroundColor: theme`colors.gray.800`,
      '&:hover': {
        backgroundColor: theme`colors.gray.700`,
      },
      boxShadow: 'none',
      cursor: 'pointer',
    };
  },

  placeholder: (provided) => {
    return {
      ...provided,
      color: 'white',
    };
  },
  option: (provided) => {
    return {
      ...provided,
      color: 'black',
    };
  },
  singleValue: (provided) => {
    return {
      ...provided,
      color: 'white',
      fontWeight: 500,
    };
  },
};

const Select: React.FC<SelectProps> = (props) => {
  return <BaseSelect {...props} styles={customStyles} className="transition" />;
};

export default Select;
