import { useEffect, useState } from 'react';
import PercentChange from '~/src/components/common/PercentChange';
import { useMarketPrices } from '~/src/hooks/useMarketPrices';
import { PriceData, TokenInfo, TokenWithOptionalPriceData } from '~/src/types';

export const Asset: React.FC<{
  token: TokenInfo;
  priceData?: PriceData;
  onClick: (t: TokenInfo) => unknown;
}> = ({ token, priceData, onClick }) => {
  return (
    <button
      className="flex items-center justify-between text-white bg-gray-800 hover:bg-gray-700 transition rounded-2xl px-4 py-2"
      onClick={() => onClick(token)}
    >
      <div className="flex items-center gap-2">
        <img className="w-8 h-8 rounded-full object-fit" src={token.logoURI} />
        <p className="font-semibold">{token.name}</p>
      </div>
      <div className="text-right">
        <p className="text-sm font-medium">{priceData?.price || '---'} USDC</p>
        <PercentChange change={priceData?.dayChange} />
      </div>
    </button>
  );
};

export const AssetSelect: React.FC<{
  tokens: TokenWithOptionalPriceData[];
  onChange: (t: TokenInfo) => unknown;
  loadPrices?: boolean;
}> = ({ onChange, loadPrices, ...props }) => {
  const [tokens, setTokens] = useState(props.tokens);

  if (loadPrices) {
    const { priceData } = useMarketPrices(tokens);

    useEffect(() => {
      setTokens(
        tokens.map((t) => {
          return { ...t, priceData: priceData[t.symbol] };
        })
      );
    }, [priceData]);
  }

  return (
    <div className="flex flex-col items-stretch gap-4">
      <div className="flex items-center justify-between text-white font-semibold px-4">
        <span>Asset</span>
        <span>Price</span>
      </div>
      {tokens.map((t) => {
        return <Asset key={t.address} token={t} priceData={t.priceData} onClick={onChange} />;
      })}
    </div>
  );
};

export default AssetSelect;
