import { WalletKitProvider } from '@gokiprotocol/walletkit';

const WalletConnectionProvider: React.FC = ({ children }) => {
  return (
    <WalletKitProvider
      defaultNetwork="devnet"
      app={{
        name: 'SolOptions',
      }}
      onConnect={console.log}
      onDisconnect={console.log}
    >
      {children}
    </WalletKitProvider>
  );
};

export default WalletConnectionProvider;
