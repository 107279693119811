import { exerciseOption, OptionContract, redeemOption, writeOption } from '@soloptions/client';
import { getOrCreateAssociatedTokenAccount } from '@soloptions/common';

import DefaultLayout from '~/src/components/layouts/DefaultLayout';
import { UserOptionBalance } from '~/src/types';
import { useOptionBalances } from '~/src/hooks/useOptionBalances';
import { useOptionContracts } from '~/src/hooks/useOptionContracts';
import { useOwnedTokens, useProvider } from '~/src/hooks';

interface Props {}

const Contract = ({ contract }: { contract: OptionContract }) => {
  const { program, provider } = useProvider();
  const write = async () => {
    if (!program || !provider) {
      return;
    }
    const writerTokenDestination = await getOrCreateAssociatedTokenAccount(provider, {
      mint: contract.writerMint,
      owner: provider.wallet.publicKey,
    });
    const optionTokenDestination = await getOrCreateAssociatedTokenAccount(provider, {
      mint: contract.optionMint,
      owner: provider.wallet.publicKey,
    });
    const writerUnderlyingFundingTokens = await getOrCreateAssociatedTokenAccount(provider, {
      mint: contract.underlyingMint,
      owner: provider.wallet.publicKey,
    });
    console.log({
      writerTokenDestination,
      optionTokenDestination,
      writerUnderlyingFundingTokens,
    });
    const result = writeOption(program, contract, {
      amount: 1,
      writerTokenDestination,
      optionTokenDestination,
      writerUnderlyingFundingTokens,
    });
  };
  return (
    <div className="mb-4 ml-4">
      writer mint: {contract.writerMint.toString()} - {contract.strike.toString()}{' '}
      {new Date(parseInt(contract.expiryTs.toLocaleString())).toLocaleString()}
      <button className="ml-4 p-2 border rounded" onClick={write}>
        write
      </button>
    </div>
  );
};

const ContractBalance = ({ balance: b }: { balance: UserOptionBalance }) => {
  const { program, provider } = useProvider();
  const exercise = async () => {
    if (!program || !provider) {
      return;
    }
    const contract = b.contract;
    const quoteTokenSource = await getOrCreateAssociatedTokenAccount(provider, {
      mint: contract.quoteMint,
      owner: provider.wallet.publicKey,
    });
    const optionTokenSource = await getOrCreateAssociatedTokenAccount(provider, {
      mint: contract.optionMint,
      owner: provider.wallet.publicKey,
    });
    const underlyingTokenDestination = await getOrCreateAssociatedTokenAccount(provider, {
      mint: contract.underlyingMint,
      owner: provider.wallet.publicKey,
    });

    await exerciseOption(program, b.contract, {
      amount: 1,
      quoteTokenSource,
      optionTokenSource,
      underlyingTokenDestination,
    });
  };
  const redeem = async () => {
    if (!program || !provider) {
      return;
    }
    const contract = b.contract;
    const quoteTokenDestination = await getOrCreateAssociatedTokenAccount(provider, {
      mint: contract.quoteMint,
      owner: provider.wallet.publicKey,
    });
    const redeemerTokenSource = await getOrCreateAssociatedTokenAccount(provider, {
      mint: contract.writerMint,
      owner: provider.wallet.publicKey,
    });
    const underlyingTokenDestination = await getOrCreateAssociatedTokenAccount(provider, {
      mint: contract.underlyingMint,
      owner: provider.wallet.publicKey,
    });
    await redeemOption(program, contract, {
      underlyingTokenDestination,
      quoteTokenDestination,
      redeemerTokenSource,
      amount: 1,
    });
  };
  return (
    <div>
      writer mint: {b.contract.writerMint.toString()} long: {b.long} short: {b.short}
      <button className="ml-4 p-2 rounded border" onClick={exercise}>
        exercise
      </button>
      <button className="ml-4 p-2 rounded border" onClick={redeem}>
        redeem
      </button>
    </div>
  );
};

const Page: React.FC<Props> = () => {
  const { contracts } = useOptionContracts();

  return (
    <>
      {contracts.map((c) => (
        <Contract key={c.publicKey.toString()} contract={c} />
      ))}
    </>
  );
};

export default () => {
  return (
    <DefaultLayout>
      <Page />
    </DefaultLayout>
  );
};
