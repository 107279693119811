import { cx } from '@emotion/css';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

import { OptionType } from '~/src/types';

export const OptionTypeButton: React.FC<{
  active: boolean;
  type: OptionType;
  onClick: (t: OptionType) => unknown;
}> = (props) => {
  const [icon, text, color] =
    props.type === 'call'
      ? [<FaCaretUp />, 'Call', 'text-green-500']
      : [<FaCaretDown />, 'Put', 'text-red-600'];
  return (
    <button
      type="button"
      className={cx(
        'flex items-center justify-center gap-1 rounded-xl py-3 transition',
        props.active ? 'bg-gray-700' : 'hover:bg-gray-800'
      )}
      onClick={() => props.onClick(props.type)}
    >
      <span className={`${color} text-2xl`}>{icon}</span>
      <span className="font-semibold">{text}</span>
    </button>
  );
};
