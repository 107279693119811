import Select, { SelectProps } from '~/src/components/common/Select';
import { formatExpiryDate } from '~/src/utils/date';

type DateSelectProps = {
  dates: Date[];
  onChange: (d: Date) => unknown;
} & Omit<SelectProps, 'onChange'>;
const DateSelect: React.FC<DateSelectProps> = ({ dates, onChange, ...props }) => {
  const options = dates.map((value) => {
    const label = formatExpiryDate(value, 'long');
    return {
      value,
      label,
    };
  });
  return <Select {...props} onChange={(d: any) => onChange(d?.value)} options={options} />;
};

export default DateSelect;
