import * as anchor from '@project-serum/anchor';
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { PublicKey, Signer } from '@solana/web3.js';
import { OptionContract, SoloptionsProgram } from './types';
import { getAssociatedTokenAddress } from '../common';
import { getProgramAddress } from './util';

export interface NewContractParams {
  payer?: Signer;
  quoteMint: PublicKey;
  underlyingMint: PublicKey;
  strike: number;
  underlyingAmount: number;
  quoteAmount: number;
  expiryTs: number;
}

export const newContract = async (
  program: SoloptionsProgram,
  params: NewContractParams
): Promise<OptionContract> => {
  const { underlyingMint, quoteMint, strike, expiryTs, payer } = params;
  const seeds = [underlyingMint, quoteMint, strike, expiryTs] as const;
  const [contract, contractBump] = await getProgramAddress(program, 'OptionsContract', ...seeds);
  const [optionMint, optionBump] = await getProgramAddress(program, 'OptionMint', ...seeds);
  const [writerMint, writerBump] = await getProgramAddress(program, 'WriterMint', ...seeds);
  const underlyingPool = await getAssociatedTokenAddress(underlyingMint, contract, true);
  const quotePool = await getAssociatedTokenAddress(quoteMint, contract, true);

  const bumps = { contract: contractBump, writer: writerBump, option: optionBump };
  await program.rpc.newContract(
    new anchor.BN(strike),
    new anchor.BN(params.underlyingAmount),
    new anchor.BN(params.quoteAmount),
    new anchor.BN(expiryTs),
    bumps,
    {
      accounts: {
        payer: payer ? payer.publicKey : program.provider.wallet.publicKey,
        contract,
        writerMint,
        optionMint,
        quoteMint,
        underlyingMint,
        quotePool,
        underlyingPool,
        systemProgram: anchor.web3.SystemProgram.programId,
        clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        tokenProgram: TOKEN_PROGRAM_ID,
        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      },
      signers: payer ? [payer] : undefined,
    }
  );

  return {
    ...params,
    expiryTs: new anchor.BN(expiryTs),
    strike: new anchor.BN(strike),
    underlyingAmount: new anchor.BN(params.underlyingAmount),
    quoteAmount: new anchor.BN(params.quoteAmount),
    payer: payer?.publicKey,
    publicKey: contract,
    underlyingPool,
    quotePool,
    writerMint,
    optionMint,
  };
};
