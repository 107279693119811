import { OptionContract, SoloptionsProgram } from '@soloptions/client';
import { atom, RecoilState } from 'recoil';
import { TokenBalanceSubscription, UserOptionBalance, UserTokenBalances } from '../types';

export const ownedTokensState = atom({
  key: 'ownedTokensState',
  // key: mint publickey
  // value: list of token accounts owned by that mint
  default: {} as UserTokenBalances,
});

export const tokenBalanceSubscriptionsState = atom({
  key: 'tokenBalanceSubscriptionsState',
  default: [] as Array<TokenBalanceSubscription>,
});

export const contractsState = atom({
  key: 'contractsState',
  default: [] as Array<OptionContract>,
});

export const optionBalancesState = atom({
  key: 'optionBalancesState',
  default: [] as Array<UserOptionBalance>,
});

export const programState: RecoilState<SoloptionsProgram> = atom({
  key: 'programState',
  default: null as unknown as SoloptionsProgram,
});
