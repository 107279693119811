import { format } from 'date-fns';

import { OptionContract } from '@soloptions/client';

import PercentChange from '~/src/components/common/PercentChange';
import { getTokenForContract } from '~/src/utils/token';

export const Market: React.FC<{
  contract: OptionContract;
  onClick: (c: OptionContract) => unknown;
}> = ({ contract, onClick }) => {
  const expiry = new Date(contract.expiryTs.toNumber());
  const strike = contract.strike.toNumber();
  // TODO(sbb): get the underlying mint decimals dynamically
  const size = contract.underlyingAmount.toNumber() / 1_000_000_000; // XXX this is zero?
  // hack for UI rn b/c of the previous incorrect contracts
  const contractSize = size > 0.0001 ? size : 1;
  const token = getTokenForContract(contract);

  // XXX: need AMM for this part. these are just random real-ish looking values
  const optionPrice = (1000 / strike) * 1000;
  const change = Math.random() * Math.random() * 10 * (Math.random() > 0.4 ? 1 : -1);

  return (
    <button
      className="group"
      tw="flex items-center justify-between text-white bg-gray-800 hover:bg-gray-700 transition rounded-2xl px-4 py-2"
      onClick={() => onClick(contract)}
    >
      <div tw="flex items-center gap-2">
        <div tw="text-left text-sm">
          <p tw="font-medium">
            {contractSize} {token.symbol} @ ${strike} Call
          </p>
          <p>{format(expiry, `MMM dd ''yy`)}</p>
        </div>
      </div>
      <div tw="text-right">
        <p tw="text-sm font-medium">${optionPrice.toFixed(2)}</p>
        <PercentChange change={change} />
      </div>
    </button>
  );
};
