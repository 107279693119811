import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { PublicKey } from '@solana/web3.js';

import AssetDropdownButton from '~/src/components/common/AssetDropdownButton';
import AssetSelect from '~/src/components/common/AssetSelect';
import ContractSize from '~/src/components/common/ContractSize';
import DateSelect from '~/src/components/common/DateSelect';
import DefaultLayout from '~/src/components/layouts/DefaultLayout';
import LoadingModal from '~/src/components/common/LoadingModal';
import {
  Form,
  FormControl,
  FormHeader,
  Input,
  InputRight,
  InputWrapper,
} from '~/src/components/common/elements';
import { ConnectWalletButton, SubmitButton } from '~/src/components/common/SubmitButton';
import { OptionTypeButton } from '~/src/components/pages/initialize';
import { OptionType, TokenInfo } from '~/src/types';
import { formatExpiryDate, formatExpiryTime, nextExpiry, nextExpiries } from '~/src/utils/date';
import { getContractSize, getStrikeStep } from '~/src/utils';
import { useInitializeContract } from '~/src/hooks/useInitializeContract';
import { useMarketPrices } from '~/src/hooks/useMarketPrices';
import { useProvider } from '~/src/hooks';
import { useSteppedNumber } from '~/src/hooks/useSteppedNumber';
import { wbtc, usdc, all as allTokens } from '~/src/tokens';

const Page = () => {
  const history = useHistory();
  const { provider, program } = useProvider();
  const { loading: initializing, initialize } = useInitializeContract();

  const [baseToken, setBaseToken] = useState<TokenInfo>(wbtc);

  const [quoteToken] = useState<TokenInfo>(usdc);
  const [optionType, setOptionType] = useState<OptionType>('call');

  const strikeStep = useMemo(() => getStrikeStep(baseToken), [baseToken]);
  const [strike, setStrike, setStrikeUnstepped] = useSteppedNumber(0, strikeStep);
  const [expiry, setExpiry] = useState(nextExpiry(new Date()));

  // TODO(cqsd): form validation
  const [valid, setValid] = useState(true);
  useEffect(() => setValid(!!provider), [provider]);

  const { priceData } = useMarketPrices([baseToken]);
  const basePriceData = priceData[baseToken.symbol];
  const currentBasePrice = basePriceData?.price || 0;
  useEffect(
    () => setStrike(currentBasePrice * getContractSize(baseToken)),
    [baseToken, currentBasePrice]
  );

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!program || !expiry) {
      return;
    }
    await initialize(program, {
      strike,
      quoteMint: new PublicKey(quoteToken.address),
      underlyingMint: new PublicKey(baseToken.address),
      underlyingAmount: getContractSize(baseToken) * Math.pow(10, baseToken.decimals),
      // Only whole amounts of USDC
      quoteAmount: Math.pow(10, quoteToken.decimals),
      expiryTs: expiry.getTime(),
    });
    history.push('/markets');
  };

  const [tokenPickerVisible, setTokenPickerVisible] = useState(false);

  if (tokenPickerVisible) {
    return (
      <div tw="bg-gray-900 p-4 h-full">
        <AssetSelect
          loadPrices
          tokens={allTokens}
          onChange={(t) => {
            setBaseToken(t);
            setTokenPickerVisible(false);
          }}
        />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <Form onSubmit={onSubmit}>
          <FormHeader alignCenter>
            <AssetDropdownButton token={baseToken} onClick={() => setTokenPickerVisible(true)} />
            <div tw="text-right">
              <p tw="text-sm">Current {baseToken.symbol} price</p>
              <p tw="text-sm font-semibold">
                {currentBasePrice} {quoteToken.symbol}
              </p>
            </div>
          </FormHeader>

          {/* form body */}
          <div tw="p-4 pt-0 rounded-xl space-y-4">
            <p tw="text-sm font-semibold">Option type</p>
            <div tw="mt-2 grid grid-cols-2 gap-4">
              <OptionTypeButton
                onClick={setOptionType}
                active={optionType === 'call'}
                type="call"
              />
              <OptionTypeButton onClick={setOptionType} active={optionType === 'put'} type="put" />
            </div>

            <div tw="mt-4 grid grid-cols-1 sm:grid-cols-5 sm:gap-4">
              <FormControl label="Size" tw="col-span-2">
                <ContractSize token={baseToken} />
              </FormControl>
              <FormControl label="Strike" tw="col-span-3">
                <InputWrapper>
                  <Input
                    type="number"
                    placeholder={`${strike}`}
                    step={getStrikeStep(baseToken)}
                    min={0}
                    value={strike}
                    onChange={(e) => setStrikeUnstepped(parseFloat(e.target.value || '0.0'))}
                    onBlur={() => setStrike(strike)}
                  />
                  <InputRight>{quoteToken.symbol}</InputRight>
                </InputWrapper>
              </FormControl>
            </div>

            <FormControl label="Expiry" tw="mt-2">
              <DateSelect
                dates={nextExpiries(new Date(), 12)}
                onChange={(d: unknown) => setExpiry(d as Date)}
                defaultValue={{ value: expiry, label: formatExpiryDate(expiry, 'long') }}
                placeholder="Expiry"
              />
            </FormControl>
            {expiry && (
              <p tw="mt-2 text-sm font-medium">
                Options will expire at {formatExpiryTime(expiry)} UTC on {formatExpiryDate(expiry)}.
              </p>
            )}
          </div>

          {provider?.wallet ? (
            <SubmitButton loading={initializing} disabled={initializing}>
              Initialize contract
            </SubmitButton>
          ) : (
            <ConnectWalletButton>Connect wallet</ConnectWalletButton>
          )}
        </Form>

        {initializing && <LoadingModal text="Initializing contract" visible={initializing} />}
      </React.Fragment>
    );
  }
};

export default () => (
  <DefaultLayout>
    <Page />
  </DefaultLayout>
);
