import { OptionContract } from '@soloptions/client';

import { all as allTokens } from '~/src/tokens';
import { TokenInfo } from '~/src/types';
import { range, steppedRound } from './common';

// TODO(sbb): This should differ by size
// BTC at 0.1 = 1k, at 0.01, 100
//
// These are written here as if contracts were for whole tokens. To get the
// actual step, you need to multiple the token step by the contract size, e.g.,
// BTC base step is 1000 but contracts are 0.01, so the step we show in the UI
// ends up being 1000 * 0.01 = 10
const KNOWN_TOKEN_STEPS: Record<string, number> = {
  BTC: 1000,
  ETH: 100,
  SOL: 10,
};

const KNOWN_TOKEN_CONTRACT_SIZES: Record<string, number> = {
  BTC: 0.01,
  ETH: 0.1,
};

export const getStrikeStep = (t: TokenInfo): number => {
  const size = getContractSize(t);
  const baseStep = KNOWN_TOKEN_STEPS[t.symbol] || 1;
  return size * baseStep;
};

export interface GetStrikesOptions {
  current: number;
  step: number;
  above: number;
  below: number;
}

// TODO(cqsd): this won't work for anything with a current price less than 1
export const getStrikes = ({ current, step, above }: GetStrikesOptions): number[] => {
  // TODO(cqsd): implement this
  const currentRounded = steppedRound(current, step);
  return range(above).map((n) => currentRounded + n * step);
};

export const getContractSize = (t: TokenInfo): number => {
  return KNOWN_TOKEN_CONTRACT_SIZES[t.symbol] || 1;
};

export const getTokenForContract = (c: OptionContract): TokenInfo => {
  const mint = c.underlyingMint.toString();
  const token = allTokens.find((t) => t.address === mint);
  if (token) {
    return token;
  }
  return {
    name: 'Unknown',
    address: mint,
    chainId: 0,
    decimals: 9, // TODO(cqsd)
    logoURI: '', // TODO(cqsd)
    symbol: '---',
    tags: [],
  };
};
