import { format, differenceInDays } from 'date-fns';

export const Expiry: React.FC<{
  expiry?: Date;
  onClick: (d?: Date) => unknown;
}> = ({ expiry, onClick }) => {
  return (
    <button
      className="flex items-center justify-between text-white bg-gray-800 hover:bg-gray-700 transition rounded-2xl px-4 py-4"
      onClick={() => onClick(expiry)}
    >
      <div className="flex items-center gap-2">
        {/* <img className="w-8 h-8 rounded-full object-fit" src={token.logoURI} /> */}
        <p className="font-semibold">{expiry ? format(expiry, `MMM dd ''yy`) : 'All expiries'}</p>
      </div>
      {expiry ? (
        <div className="text-right">
          <p className="text-sm font-light">
            {differenceInDays(expiry, new Date())} days until expiry
          </p>
        </div>
      ) : null}
    </button>
  );
};

export const ExpirySelect: React.FC<{
  expiries: Date[];
  onChange: (d?: Date) => unknown;
  loadPrices?: boolean;
}> = ({ onChange, expiries, ...props }) => {
  return (
    <div className="flex flex-col items-stretch gap-4">
      <div className="flex items-center justify-between text-white font-semibold px-4">
        <span>Expiry Date</span>
        <span>Days Until Expiry</span>
      </div>
      <Expiry key="expiry-none" onClick={() => onChange(undefined)} />
      {expiries.map((e) => {
        return <Expiry key={e.getTime()} expiry={e} onClick={onChange} />;
      })}
    </div>
  );
};

export default ExpirySelect;
