import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useOwnedTokens } from '.';
import { optionBalancesState } from '../state';
import { UserOptionBalance } from '../types';
import { useOptionContracts } from './useOptionContracts';

export const useOptionBalances = () => {
  const { contracts } = useOptionContracts();
  const { ownedTokenAccounts, loading: loadingTokens } = useOwnedTokens();
  const [userOptionBalances, setUserOptionBalances] = useRecoilState(optionBalancesState);

  useEffect(() => {
    const _balances: Record<string, UserOptionBalance> = {};
    if (contracts.length === 0 || Object.keys(ownedTokenAccounts).length === 0) {
      return;
    }
    // This seems like a waste
    Object.keys(ownedTokenAccounts).forEach((key) => {
      const contract = contracts.find(
        (c) => c.optionMint.toString() === key || c.writerMint.toString() === key
      );
      if (contract) {
        const contractKey = contract.publicKey.toString();
        _balances[contractKey] = _balances[contractKey] || { short: 0, long: 0, contract };
        ownedTokenAccounts[key].forEach((account) => {
          if (account.mint.toString() === contract.optionMint.toString()) {
            _balances[contractKey].long += account.amount;
          } else {
            _balances[contractKey].short += account.amount;
          }
        });
      }
    });
    setUserOptionBalances(Object.values(_balances));
  }, [contracts, ownedTokenAccounts]);

  return { userOptionBalances, loading: loadingTokens };
};
