import tw from 'twin.macro';
import { FaCaretDown } from 'react-icons/fa';

import { TokenInfo } from '~/src/types';

const Icon = tw.img`h-6 w-6 object-cover rounded-full`;

const AssetDropdownButton: React.FC<{ token: TokenInfo; onClick: () => unknown }> = ({
  token,
  ...props
}) => {
  return (
    <button tw="flex items-center gap-2 text-lg font-semibold" {...props}>
      <Icon src={token.logoURI} />
      <span>{token.symbol}</span>
      <FaCaretDown />
    </button>
  );
};

export default AssetDropdownButton;
