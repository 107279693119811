import React, { useMemo, useState } from 'react';
import { isSameDay } from 'date-fns';

import { OptionContract } from '@soloptions/client';

import AssetDropdownButton from '~/src/components/common/AssetDropdownButton';
import DefaultLayout from '~/src/components/layouts/DefaultLayout';
import LoadingModal from '~/src/components/common/LoadingModal';
import { ConnectWalletButton } from '~/src/components/common/SubmitButton'; // XXX: shouldn't be necessary in this view
import { FormHeader } from '~/src/components/common/elements';
import { Market, MintForm, TokenExpirySelect } from '~/src/components/pages/markets';
import { TokenInfo } from '~/src/types';
import { all as allTokens, wbtc } from '~/src/tokens';
import { formatExpiryDate } from '~/src/utils';
import { useOptionContracts } from '~/src/hooks/useOptionContracts';
import { useHistory } from 'react-router';
import { useProvider } from '~/src/hooks';

const allDates = (contracts: OptionContract[]): Date[] => {
  const seen = new Set<number>(contracts.map((c) => c.expiryTs.toNumber()));
  return Array.from(seen.values())
    .sort()
    .map((e) => new Date(e));
};

const Page = () => {
  const history = useHistory();
  const [baseToken, setBaseToken] = useState<TokenInfo>(wbtc);

  // XXX: this call shouldn't need a wallet to work
  const { contracts, loading } = useOptionContracts();
  const [expiry, setExpiry] = useState<Date | undefined>(undefined);
  const selectedContracts = useMemo(() => {
    const cs = expiry
      ? contracts.filter((c) => isSameDay(new Date(c.expiryTs.toNumber()), expiry))
      : contracts;
    return cs.filter((c) => c.underlyingMint.toString() === baseToken.address);
  }, [baseToken, expiry, contracts]);
  const expiries = useMemo(() => allDates(selectedContracts), [selectedContracts]);

  const [contract, setContract] = useState<OptionContract | undefined>();
  const handleClickContract = (contract: OptionContract) => {
    setContract(contract);
  };

  const [tokenPickerVisible, setTokenPickerVisible] = useState(false);
  const [expiryPickerVisible, setExpiryPickerVisible] = useState(false);

  const { provider } = useProvider(); // XXX: shouldn't be necessary in this view

  if (contract) {
    return (
      <MintForm
        contract={contract}
        onCancel={() => setContract(undefined)}
        afterSubmit={() => history.push('/balances')}
      />
    );
  } else if (tokenPickerVisible || expiryPickerVisible) {
    return (
      <TokenExpirySelect
        kind={tokenPickerVisible ? 'token' : 'expiry'}
        tokens={allTokens}
        expiries={expiries}
        onPickToken={(t) => {
          setBaseToken(t);
          setTokenPickerVisible(false);
        }}
        onPickExpiry={(d) => {
          setExpiry(d);
          setExpiryPickerVisible(false);
        }}
      />
    );
  } else {
    return (
      <React.Fragment>
        {/* TODO(cqsd): refactor */}
        {/* XXX: useOptionContracts currently relies on an authenticated
        provider (which shouldn't be necessary) so there's some ugly
        conditionals here to show the "connect wallet" button */}
        {loading ? (
          <div className="min-h-[8rem]"></div>
        ) : (
          <div tw="p-4 flex flex-col items-stretch gap-4">
            {provider && (
              <FormHeader alignCenter tw="text-white">
                <AssetDropdownButton
                  token={baseToken}
                  onClick={() => setTokenPickerVisible(true)}
                />
                <button onClick={() => setExpiryPickerVisible(true)}>
                  <p tw="text-sm">Contract expiry</p>
                  <p tw="text-sm font-semibold">
                    {expiry ? formatExpiryDate(expiry, 'short') : 'Select expiry'}
                  </p>
                </button>
              </FormHeader>
            )}
            {provider &&
              (selectedContracts.length ? (
                selectedContracts.map((c, i) => {
                  return <Market key={i} contract={c} onClick={handleClickContract} />;
                })
              ) : (
                <p tw="p-4 text-center text-white font-semibold">No markets found</p>
              ))}
            {!provider && <ConnectWalletButton>Connect wallet</ConnectWalletButton>}
          </div>
        )}
        <LoadingModal visible={loading} text="Loading markets" />
      </React.Fragment>
    );
  }
};

export default () => (
  <DefaultLayout>
    <Page />
  </DefaultLayout>
);
