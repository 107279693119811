import * as anchor from '@project-serum/anchor';
import { PublicKey } from '@solana/web3.js';
import { SoloptionsProgram } from './types';

export const numToBigEndianByteArray = (num: number, bits: 8 | 16 | 32 | 64) => {
  const bn = new anchor.BN(num);
  const numBuf = bn.toArrayLike(Buffer, 'be');
  const buf = Buffer.alloc(bits / 8);
  numBuf.copy(buf, buf.length - numBuf.length);
  return buf;
};

export const getProgramAddress = async (
  program: SoloptionsProgram,
  kind: 'OptionsContract' | 'WriterMint' | 'OptionMint',
  underlyingMint: PublicKey,
  quoteMint: PublicKey,
  strike: number,
  expiry: number
) => {
  return await anchor.web3.PublicKey.findProgramAddress(
    [
      Buffer.from(kind),
      underlyingMint.toBuffer(),
      quoteMint.toBuffer(),
      numToBigEndianByteArray(strike, 64),
      numToBigEndianByteArray(expiry, 64),
    ],
    program.programId
  );
};
