import AssetSelect from '~/src/components/common/AssetSelect';
import ExpirySelect from '~/src/components/common/ExpirySelect';
import { TokenInfo } from '~/src/types';

export const TokenExpirySelect: React.FC<{
  tokens: TokenInfo[];
  expiries: Date[];
  kind: 'token' | 'expiry';
  onPickToken: (t: TokenInfo) => unknown;
  onPickExpiry: (d?: Date) => unknown;
}> = ({ kind, tokens, expiries, onPickToken, onPickExpiry }) => {
  return (
    <div className="bg-gray-900 p-4">
      {kind === 'token' ? (
        <AssetSelect loadPrices tokens={tokens} onChange={onPickToken} />
      ) : (
        <ExpirySelect expiries={expiries} onChange={onPickExpiry} />
      )}
    </div>
  );
};

export default TokenExpirySelect;
