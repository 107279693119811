import { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { PublicKey } from '@solana/web3.js';
import { AccountLayout, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { useConnectedWallet } from '@saberhq/use-solana';

import { TokenAccount, UserTokenBalances } from '~/src/types';
import { getConnection } from '~/src/utils/connection';
import { ownedTokensState, tokenBalanceSubscriptionsState } from '~/src/state';

const convertAccountInfo = (accountInfo: any, pubKey: PublicKey): TokenAccount => {
  const amountBuffer = Buffer.from(accountInfo.amount);
  const amount = amountBuffer.readUIntLE(0, 8);
  return {
    amount,
    mint: new PublicKey(accountInfo.mint),
    pubKey,
  };
};

export const useOwnedTokens = () => {
  const connection = getConnection();
  const wallet = useConnectedWallet();
  const [ownedTokenAccounts, setOwnedTokenAccounts] = useRecoilState(ownedTokensState);
  const [subscriptions, setSubscriptions] = useRecoilState(tokenBalanceSubscriptionsState);
  const [loading, setLoading] = useState(false);

  const pubKey = wallet?.publicKey;

  const subscribeToTokenAccount = useCallback(
    (publicKey: PublicKey) => {
      if (subscriptions.find((s) => s.publicKey.equals(publicKey))) {
        return;
      }

      const subscriptionId = connection.onAccountChange(publicKey, (_account) => {
        const listenerAccountInfo = AccountLayout.decode(_account.data);
        const listenerAccount = convertAccountInfo(listenerAccountInfo, publicKey);
        setOwnedTokenAccounts((prevOwnedTokenAccounts) => {
          const mintAsString = listenerAccount.mint.toString();
          const newMintState = prevOwnedTokenAccounts[mintAsString].filter(
            (tokenAccount) => !tokenAccount.pubKey.equals(publicKey)
          );
          newMintState.push(listenerAccount);
          return {
            ...prevOwnedTokenAccounts,
            [mintAsString]: newMintState,
          };
        });
      });
      setSubscriptions((oldSubscriptions) => {
        const newSubscriptions = oldSubscriptions.filter(
          (subscription) => subscription.id !== subscriptionId
        );
        newSubscriptions.push({ id: subscriptionId, publicKey });
        return newSubscriptions;
      });
    },
    [connection, wallet]
  );

  // TODO(sbb): Have connection as a dependency as well
  useEffect(() => {
    if (!pubKey || !connection) {
      return;
    }
    (async () => {
      setLoading(true);
      const resp = await connection.getTokenAccountsByOwner(
        pubKey,
        { programId: TOKEN_PROGRAM_ID },
        connection.commitment
      );
      if (resp?.value) {
        const _ownedTokenAccounts = resp.value.reduce((mapping, { pubkey, account }) => {
          const accountInfo = AccountLayout.decode(account.data);
          const initialAccount = convertAccountInfo(accountInfo, pubkey);
          const mint = initialAccount.mint.toString();
          mapping[mint] = mapping[mint] || [];
          mapping[mint].push(initialAccount);
          subscribeToTokenAccount(new PublicKey(pubkey));
          return mapping;
        }, {} as UserTokenBalances);
        setOwnedTokenAccounts(_ownedTokenAccounts);
      }
      setLoading(false);
    })();
  }, [pubKey]);

  return {
    ownedTokenAccounts,
    subscribeToTokenAccount,
    loading,
  };
};
