import WalletConnectionProvider from './components/WalletConnectionProvider';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { HashRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import '@solana/wallet-adapter-react-ui/styles.css';

import { Routes } from './routes';

const App = () => (
  <HashRouter>
    <Routes />
  </HashRouter>
);

const withProviders = (app: React.ReactNode) => () =>
  (
    <RecoilRoot>
      <WalletConnectionProvider>
        <WalletModalProvider>{app}</WalletModalProvider>
      </WalletConnectionProvider>
    </RecoilRoot>
  );

export default withProviders(<App />);
