import { AssetPair, OptionContract, SoloptionsProgram } from './types';

export const getAllContracts = async (program: SoloptionsProgram) => {
  const contracts = await program.account.optionsContract.all();
  return contracts.map((c) => ({
    ...c.account,
    publicKey: c.publicKey,
  })) as unknown as Array<OptionContract>;
};

export const getActiveExpiryForPair = (contracts: Array<OptionContract>, pair: AssetPair) => {
  return contracts
    .filter((c) => c.underlyingMint.equals(pair.underlying) && c.quoteMint.equals(pair.quote))
    .map((c) => c.expiryTs);
};

export const getActiveStrikes = (
  contracts: Array<OptionContract>,
  pair: AssetPair,
  expiry: number
) => {
  return contracts
    .filter(
      (c) =>
        c.underlyingMint.equals(pair.underlying) &&
        c.quoteMint.equals(pair.quote) &&
        c.expiryTs.toNumber() === expiry
    )
    .map((c) => c.strike);
};
