import { OptionContract, writeOption as writeOptionBase } from '@soloptions/client';
import { getOrCreateAssociatedTokenAccount } from '@soloptions/common';
import { Provider } from '@project-serum/anchor';
import { SoloptionsProgram } from '@soloptions/client';

export const writeOption = async (
  provider: Provider,
  program: SoloptionsProgram,
  contract: OptionContract,
  amount: number
) => {
  if (amount <= 0) {
    throw new Error('Amount must be positive');
  }
  const writerTokenDestination = await getOrCreateAssociatedTokenAccount(provider, {
    mint: contract.writerMint,
    owner: provider.wallet.publicKey,
  });
  const optionTokenDestination = await getOrCreateAssociatedTokenAccount(provider, {
    mint: contract.optionMint,
    owner: provider.wallet.publicKey,
  });
  const writerUnderlyingFundingTokens = await getOrCreateAssociatedTokenAccount(provider, {
    mint: contract.underlyingMint,
    owner: provider.wallet.publicKey,
  });
  return writeOptionBase(program, contract, {
    amount,
    writerTokenDestination,
    optionTokenDestination,
    writerUnderlyingFundingTokens,
  });
};
