import { addDays, addWeeks, format, nextSaturday, startOfDay, subSeconds } from 'date-fns';
import { range } from './common';

/**
 * Return 11:59:59 UTC of the nearest future Friday.
 */
export const nextExpiry = (d: Date): Date => {
  const sat = nextSaturday(addDays(startOfDay(d), 1));
  sat.setUTCHours(0, 0, 0, 0);
  return subSeconds(sat, 1);
};

export const nextExpiries = (d: Date, count: number): Date[] => {
  const start = nextExpiry(d);
  return range(count).map((n) => addWeeks(start, n));
};

export const formatExpiryDate = (d: Date, t: 'long' | 'short' = 'short'): string => {
  return t === 'short' ? format(d, 'MMM dd, yyyy') : format(d, 'EEEE MMMM dd, yyyy');
};

export const formatExpiryTime = (d: Date, t: 'long' | 'short' = 'short'): string => {
  return t === 'short'
    ? format(d, 'HH:mm')
    : (() => {
        throw new Error('Long format unimplemented, see utils/date.ts');
      })();
};
