import React, { useState } from 'react';

import { OptionContract } from '@soloptions/client';

import * as api from '~/src/utils/api';
import ContractSize from '~/src/components/common/ContractSize';
import LoadingModal from '~/src/components/common/LoadingModal';
import { Form, FormControl, FormHeader, Input } from '~/src/components/common/elements';
import { ConnectWalletButton } from '~/src/components/common/SubmitButton';
import { SubmitButton } from '~/src/components/common/SubmitButton';
import { formatExpiryDate, getContractSize, getTokenForContract } from '~/src/utils';
import { useProvider } from '~/src/hooks';

// XXX(cqsd): hardcoded for Call options
export const MintForm: React.FC<{
  contract: OptionContract;
  afterSubmit: () => unknown; // hm, very unwise
  onCancel: () => unknown;
}> = ({ contract, onCancel, afterSubmit }) => {
  const { provider, program } = useProvider();

  const [amount, setAmount] = useState<number>(1);
  const token = getTokenForContract(contract);
  const size = getContractSize(token);
  const strike = contract.strike.toNumber();
  const expiry = new Date(contract.expiryTs.toNumber());
  const direction = 'call';
  const lockup = size * amount;
  const description = `${size} ${token.symbol} @${strike} ${direction}`;

  const [loading, setLoading] = useState(false);
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    if (!provider || !program) {
      return undefined;
    }
    try {
      await api.writeOption(provider, program, contract, amount);
      if (typeof afterSubmit === 'function') {
        afterSubmit();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Form onSubmit={onSubmit}>
        <FormHeader>
          <div tw="font-semibold">
            <p tw="text-lg">{description}</p>
            <p tw="text-sm">{formatExpiryDate(expiry, 'long')}</p>
          </div>
          <p>
            <span
              tw="cursor-pointer text-sm font-semibold text-gray-200 hover:text-white transition"
              onClick={onCancel}
            >
              Cancel
            </span>
          </p>
        </FormHeader>

        <div tw="p-4 rounded-xl space-y-4">
          <div tw="grid grid-cols-1 sm:grid-cols-2 sm:gap-4">
            <FormControl label="Size" tw="col-span-1">
              <ContractSize token={token} />
            </FormControl>
            <FormControl label="Amount" tw="col-span-1">
              <Input
                type="number"
                placeholder="Amount"
                step={1}
                min={1}
                value={amount}
                onChange={(e) => setAmount(parseInt(e.target.value || '1'))}
              />
            </FormControl>
          </div>

          <div tw="text-sm font-medium text-white cursor-default space-y-2">
            <p>
              You are minting {description}s expiring at {expiry.getUTCHours()} UTC on{' '}
              {formatExpiryDate(expiry, 'long')}.
            </p>
            <p>
              Clicking "Mint" will give you {amount} option {amount > 1 ? 'tokens' : 'token'} and
              lock up {lockup} {token.symbol} until the contract expires.
            </p>
          </div>
        </div>

        {provider?.wallet ? (
          <SubmitButton loading={false} disabled={false}>
            Mint {amount} {amount > 1 ? 'options' : 'option'}
          </SubmitButton>
        ) : (
          <ConnectWalletButton>Connect wallet</ConnectWalletButton>
        )}
      </Form>

      <LoadingModal visible={loading} text="Minting..." />
    </React.Fragment>
  );
};
