import * as anchor from '@project-serum/anchor';
import { PublicKey, Keypair, SYSVAR_CLOCK_PUBKEY } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { OptionContract, SoloptionsProgram } from './types';

export interface WriteOptionParams {
  writerAccount?: Keypair;
  writerUnderlyingFundingTokens: PublicKey;
  writerTokenDestination: PublicKey;
  optionTokenDestination: PublicKey;
  amount: number;
}

export const writeOption = async (
  program: SoloptionsProgram,
  contract: OptionContract,
  params: WriteOptionParams
) => {
  const {
    amount,
    writerAccount,
    writerUnderlyingFundingTokens,
    writerTokenDestination,
    optionTokenDestination,
  } = params;

  return await program.rpc.optionWrite(new anchor.BN(amount), {
    accounts: {
      contract: contract.publicKey,
      optionMint: contract.optionMint,
      quoteMint: contract.quoteMint,
      optionTokenDestination,
      underlyingMint: contract.underlyingMint,
      underlyingPool: contract.underlyingPool,
      writerMint: contract.writerMint,

      writerTokenDestination,
      writerAuthority: writerAccount ? writerAccount.publicKey : program.provider.wallet.publicKey,
      userUnderlyingFundingTokens: writerUnderlyingFundingTokens,

      tokenProgram: TOKEN_PROGRAM_ID,
      clock: SYSVAR_CLOCK_PUBKEY,
    },
    signers: writerAccount ? [writerAccount] : undefined,
  });
};
