import Select from '~/src/components/common/Select';
import { TokenInfo } from '~/src/types';
import { getStrikes, GetStrikesOptions } from '~/src/utils';

type ExpirySelectorProps = {
  onChange: (d?: Date) => unknown;
  quoteToken: TokenInfo;
} & GetStrikesOptions;
export const StrikeSelector: React.FC<ExpirySelectorProps> = ({
  current,
  onChange,
  above,
  below,
  step,
  quoteToken,
}) => {
  const options = getStrikes({ current, step, above, below }).map((value) => {
    return {
      value,
      label: `${value}${quoteToken.symbol}`,
    };
  });
  return (
    <Select placeholder="Strike" onChange={(d: any) => onChange(d?.value)} options={options} />
  );
};
