import tw from 'twin.macro';
import { Nav } from './Nav';

const Card = tw.div`rounded-3xl bg-gray-900 relative`;

const DefaultLayout: React.FC = ({ children }) => {
  return (
    <div className="min-h-screen w-screen bg-gray-800">
      <Nav />
      <main className="px-4 py-16 relative">
        <section tw="container flex justify-center mx-auto">
          <Card tw="overflow-hidden w-full sm:w-[400px]">{children}</Card>
        </section>
      </main>
    </div>
  );
};

export default DefaultLayout;
