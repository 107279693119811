import { getAllContracts, OptionContract } from '@soloptions/client';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { contractsState } from '~/src/state';
import { useProvider } from './useProvider';

const sortContract = (a: OptionContract, b: OptionContract) => {
  const diff = a.expiryTs.toNumber() - b.expiryTs.toNumber();
  if (diff !== 0) {
    return diff;
  }
  return a.strike.toNumber() - b.strike.toNumber();
};

export const useOptionContracts = () => {
  const { program } = useProvider();
  const [contracts, setContracts] = useRecoilState(contractsState);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!program) {
      return;
    }
    setLoading(true);
    getAllContracts(program)
      .then((newContracts) => newContracts.sort(sortContract))
      .then(setContracts)
      .finally(() => setLoading(false));
  }, [program]);

  return { contracts, loading };
};
