import ReactTooltip from 'react-tooltip';
import { Input, InputRight, InputWrapper } from '~/src/components/common/elements';
import { TokenInfo } from '~/src/types';
import { getContractSize } from '~/src/utils';

const ContractSize: React.FC<{ token: TokenInfo }> = ({ token, ...props }) => {
  return (
    <InputWrapper
      {...props}
      data-place="left"
      data-tip="The amount of the underlying asset per contract"
    >
      <ReactTooltip />
      <Input disabled value={getContractSize(token)} tw="flex-grow tracking-wide" />
      <InputRight>{token.symbol}</InputRight>
    </InputWrapper>
  );
};

export default ContractSize;
